@tailwind base;
@tailwind components;
@tailwind utilities;

*  {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
}
